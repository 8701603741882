import React from 'react'
import "./LeftPanelTabLabels.scss"

const LeftPanelTabLabels = ({ classes, icon, label, selected, setSelected, subContent }) => {
    console.log("subContent", subContent)
    return (
        <div className={` ${selected === label?"leftPanelTabLabel-containerActive":"leftPanelTabLabel-container"}`} 
        onClick={()=>{
            if(subContent === undefined){
                setSelected(label); 
            } else {
                setSelected(label); 
                subContent(label)
            }
            }}>
            {icon && icon}
            <div className={`${selected === label ? "leftPanelTabLabel-textActive" : "leftPanelTabLabel-text"}`}>{label}</div>
        </div>
    )
}

export default LeftPanelTabLabels