export const copyRightTitle =<p>Copyright&copy; 2025. TECHEAGLE Innovations Pvt.Ltd.</p>
// export const backEndIP ="https://order.techeagle.in"
// export const clearskyIP = "https://clearsky.techeagle.in"


export const backEndIP ="https://test.shop.techeagle.org"
export const clearskyIP = "https://test.clearsky.techeagle.org"


// export const backEndIP ="https://training.shop.techeagle.org"
// export const clearskyIP = "https://training.clearsky.techeagle.org"


// export const backEndIP ="http://localhost:5500"
// export const clearskyIP = "http://localhost:5000"
export const weightLimit=2500