import React, { useContext, useEffect, useRef, useState } from 'react'
import { DrawerContext } from '../context/DrawerContext';
import { CustomerDetailContext } from '../context/CustomerDetailContext';
import { Button, DatePicker, Form, Radio, RadioGroup } from 'rsuite';
import "./QuickCheckoutCart.scss"
import { backEndIP } from '../components/configuration';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CartRowItem from './CartRowItem';
import { fetchOrderDeliverySlots } from '../Utility/fetchOrderDeliverySlots';

const QuickCheckoutCart = ({
    bagList = [],
    setBagList,
    userHubLocation,
    handleDeleteCartItem,
    // handleUpdateItemQuantityInBag,
    handleNotifyAlert,
    SetLoadingVisibility,
    setSelectedSubContent,
    allLocations,
    userCookie, myLocation,
    setContentOption,
    setOrderNo,
    setSelectedTab,
    totalWeight,
    setTotalPrice,
    setTotalWeight,
    calculateTotalPrice,
    calculateTotalWeight,
}) => {

    const { openDrawer, setOpenDrawer, placement, setOpenDeliveryTypeModal } = useContext(DrawerContext);
    const { payloadDetails, setPayloadDetails, deliveryDate, setDeliveryDate, source_location, deliveryType, setDeliveryType, orderType, setOrderType, customerData, setCustomerData, destLocation, setDestLocation, recievedOn, setRecievedOn, destination_location, urgentReason, setUrgentReason, setAvailableOrderSlots, availableOrderSlots } = useContext(CustomerDetailContext)
    const { hubLocation, setHubLocation } = useContext(DrawerContext);
    const [btnColor, setBtnColor] = useState(["#FD6125", "#FD6125"]);
    const InputQuantityRef = useRef();
    const [userHubLocationName, setUserHubLocationName] = useState("")
    const [userLocationName, setUserLocationName] = useState("")
    // const [deliveryType, setDeliveryType] = useState('Urgent');
    const [selectDateVisibility, setSelectDateVisibility] = useState(false); // Toggle date picker
    // const [deliveryDate, setDeliveryDate] = useState(dayjs(new Date()).add(1, "day").hour(18).minute(0))
    const [avaiblableDestLocations, setAvaiblableDestLocations] = useState({});
    // const [orderType, setOrderType] = useState("Self")
    const [quantity, setQuantity] = useState(0)
    const maxWeight = 2500
    const navigate = useNavigate()
    const urgentReasonOption = [
        { label: "Life risk" },
        { label: "Critical supplies" }
    ]
    const extraWeight = maxWeight - totalWeight
    useEffect(() => {
        setTotalPrice(calculateTotalPrice());
        setTotalWeight(calculateTotalWeight());

    }, [bagList])

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchOrderDeliverySlots(userCookie, setAvailableOrderSlots, handleNotifyAlert);
            } catch (error) {
                console.error("Error fetching delivery slots:", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        // console.log(deliveryDate, destLocation)
        const tempMyLoc = {}
        allLocations.forEach((loc, i) => {
            if (loc._id === myLocation) {
                tempMyLoc[myLocation] = loc.location_name;
                tempMyLoc[loc.location_name] = loc._id;
            }
            if (allLocations[i]._id === userHubLocation) {
                setHubLocation({ location_id: userHubLocation, location_name: `${allLocations[i].location_name} (${allLocations[i].tag_name})` })
            }
        })
        setAvaiblableDestLocations(tempMyLoc)

    }, [allLocations])


    // Calculate total weight
    // const calculateTotalWeight = () =>
    //     bagList.reduce(
    //         (total, item) => total + (item.quantity * item.weight || 0),
    //         0
    //     );

    useEffect(() => {
        allLocations.map((loc) => {
            if (loc._id === userHubLocation) {
                setUserHubLocationName(loc.location_name)
            }
        })
        allLocations.map((loc) => {
            if (loc._id === myLocation) {
                setUserLocationName(loc.location_name)
            }
        })
    }, [])
    // console.log("userHubLocation", userHubLocation)
    // console.log("userCookie", userCookie)
    const updateCartInput = (newQuantity, id) => {
        setQuantity(newQuantity);
        SetLoadingVisibility(true)
        const updatedCart = bagList.map((item) =>
            item._id === id ? { ...item, quantity: newQuantity } : item
        );
        // console.log("newQuantity",newQuantity)

        axios({
            url: `${backEndIP}/cart-add/update-cart`,
            method: "PUT",
            headers: {
                authorization: userCookie.token,
            },
            data: { cart: updatedCart, quantity: newQuantity },
        })
            .then((response, updatedCart) => {
                //console.log("RESPONSE",newQuantity);
                setBagList(response.data.cart);
                // handleNotifyAlert(
                //   "notify",
                //   `${product.name} : ${newQuantity} unit(s) updated successfully`,
                //   "success",
                //   () => { }
                // );
                SetLoadingVisibility(false)
            })
            .catch((error) => {
                SetLoadingVisibility(false)
                console.error("Error updating cart:", error.response?.data || error);
            });
    };
    const handleChangeQuantity = (value, index) => {
        if (value < 1) value = 1; // Prevent negative or zero values
        updateCartInput(value, index);
    };

    const PlaceUserOrder = () => {
        console.log({
            orderType: orderType,
            cart: bagList,
            destination_location: destLocation.split(",")[0],
            source_location: userHubLocation,
            delivery_date: deliveryDate,
            delivery_type: deliveryType,
            customerData: { ...customerData },
            urgentReason: urgentReason
        })
        SetLoadingVisibility(true);

        axios({
            url: backEndIP + "/order/placed-order",
            method: "POST",
            headers: {
                authorization: userCookie.token,
            },
            data: {
                orderType: orderType,
                cart: bagList,
                destination_location: orderType === "Self" ? destination_location : destLocation.split(",")[0],
                source_location: orderType === "Self" ? source_location : userHubLocation,
                delivery_date: deliveryDate,
                delivery_type: deliveryType,
                customerData: { ...customerData },
                urgentReason: urgentReason,
                recieved_on: userCookie.permission === "Granted" ?recievedOn: "System (OMS)"
            }
            // data: { id: product._id , quantity:1 }
        })
            .then((resp) => {
                SetLoadingVisibility(false);
                console.log("resp.data.order_id", resp.data.order_id);
                setBagList(resp.data.cart);
                // setCartQuantityUpdated(true);
                handleNotifyAlert("notify", "Order placed succesfully", "success", () => { })
                setDestLocation("Select")
                setDeliveryType("")
                setCustomerData({ name: "", email: "", phoneNo: "" })
                setOrderType("Self")
                // setSelectDateVisibility(false)
                setRecievedOn("");
                // setPayloadDetails({ type: "", description: "", weight: 0 })
                setDeliveryDate("")
                setSelectDateVisibility(false)
                if (userCookie.permission === "Granted") {
                    navigate("/manage-orders")
                    setSelectedSubContent("Manage Orders")
                    setSelectedTab("Manage Orders")
                } else {
                    setOrderNo(resp.data.order_id);
                    console.log("resp.data.order_no", resp.data);
                    setContentOption("SpecficOrder")
                    // navigate(`/order/${resp.data.order_no}`)
                    setSelectedSubContent("SpecficOrder")
                    setSelectedTab("My Orders")
                }

            })
            .catch((err) => {
                SetLoadingVisibility(false);
                console.log(err);
                if (err.response.data.message) {
                    // window.alert(err.response.data.message);
                    handleNotifyAlert("notify", err.response.data.message, "failed", () => { })
                }
                else handleNotifyAlert("notify", "some error occurered", "failed", () => { });
                console.log(err);
                fetchOrderDeliverySlots(userCookie, setAvailableOrderSlots, handleNotifyAlert);
            });
    }
    const handlePlaceOrderClick = () => {
        console.log({
            orderType: orderType,
            cart: bagList,
            destination_location: destLocation.split(",")[0],
            source_location: userHubLocation,
            delivery_date: deliveryDate,
            delivery_type: deliveryType,
            customerData: { ...customerData },
            urgentReason: urgentReason
        })
        if (orderType === "Self") {
            //   if (userCookie.permission === "Granted") {
            //     if ( deliveryType !== "") {
            //       handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${avaiblableDestLocations[myLocation]}?`, "alert", PlaceUserOrder)
            //     }
            //     else if (deliveryType === "") {
            //       handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
            //     }
            //     else {
            //       SetLoadingVisibility(false);
            //       handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
            //     }
            //   } else {
            // setDestLocation(myLocation)
            if (deliveryType !== "") {
                if (deliveryType === "Urgent") {
                    if (urgentReason === "" || urgentReason === "Select") {
                        handleNotifyAlert("notify", `Please Select "Urgent delivery reason" for the order`, "alert", () => { })
                    } else {
                        handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${avaiblableDestLocations[myLocation]}?`, "alert", PlaceUserOrder)
                    }
                } else {
                    handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${avaiblableDestLocations[myLocation]}?`, "alert", PlaceUserOrder)
                }
            }
            else if (deliveryType === "") {
                handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
            }
            //   }

        } else {
            if (customerData.name === "" && customerData.email === "" && customerData.phoneNo === "") {
                handleNotifyAlert("notify", "Please fill all the customers details", "alert", () => { })
            } else if (customerData.phoneNo.slice(3).length !== 10) {
                // setPhoneNoError(true)
                handleNotifyAlert("notify", "Enter Vaild Phone no", "alert", () => { })
            }
            else {
                // const tempCustomerData ={ ...customerData, phoneNo: `+91${phoneNoElementRef.current.value}` }
                // setCustomerData(tempCustomerData)
                if (destLocation !== "Select" && deliveryType !== "") {
                    console.log("customerData", customerData)
                    if (deliveryType === "Urgent") {
                        if (urgentReason === "" || urgentReason === "Select") {
                            handleNotifyAlert("notify", `Please Select "Urgent delivery reason" for the order`, "alert", () => { })
                        } else {
                            handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${avaiblableDestLocations[myLocation]}?`, "alert", PlaceUserOrder)
                        }
                    } else if (deliveryType === "Scheduled") {
                        handleNotifyAlert("alert", `Are you sure you want to place order for Destination location : ${destLocation.split(",")[1]}?`, "alert", PlaceUserOrder)
                    }
                }
                else if (destLocation === "Select") {
                    handleNotifyAlert("notify", "Please select destination Location", "alert", () => { })
                }
                else if (deliveryType === "") {
                    handleNotifyAlert("notify", `Please select "delivery type" for the order`, "alert", () => { })
                }
                else {
                    SetLoadingVisibility(false);
                    handleNotifyAlert("notify", "Please update Cart first", "alert", () => { })
                }
            }
        }
    }
 const handleDeliveryRadioChange = (value) => {
        setDeliveryType(value);
        if (value === "Urgent") {
            const currentDate = new Date();
            const currentHour = currentDate.getHours();
            const currentMinutes = currentDate.getMinutes();

            let deliveryDate = new Date(currentDate);

            if (currentHour < 12 || (currentHour === 12 && currentMinutes === 0)) {
                deliveryDate.setHours(18, 0, 0, 0);
            } else {
                deliveryDate.setDate(deliveryDate.getDate() + 1);
                deliveryDate.setHours(12, 0, 0, 0);
            }

            const formattedDate = dayjs(deliveryDate).format("MM/DD/YYYY hh:mm A"); // Format the date
            console.log("formattedDate", formattedDate);
            setSelectDateVisibility(false);
            setDeliveryDate(formattedDate); // Set the formatted delivery date

        } else {
            setSelectDateVisibility(true);

        }
        // setDeliveryTypeError("")
        // console.log("deliverytype", deliveryType);
    };
    // console.log("bagList", bagList)

    return (

        <div className="quickCart-container">
            {/* <Drawer.Header className="quickCart-container__drawer-header"> */}
            <div className="quickCart-container__quick-checkout-and-cart">
                <div className="quickCart-container__quick-checkout-and-cart__quick-checkout">Quick Checkout</div>
                <div className="quickCart-container__quick-checkout-and-cart__cart">
                    <div className="quickCart-container__quick-checkout-and-cart__cart__viewBtn-container">
                        <button
                            // appearance="primary"
                            className="quickCart-container__quick-checkout-and-cart__cart__view-button"
                            onClick={() => {
                                navigate("/cart-list")
                                setSelectedSubContent("Cart List");
                                setOpenDrawer(false);
                                setSelectedTab("Home")
                                // setOpenDeliveryTypeModal(true)
                            }}
                        >
                            View Cart
                        </button>
                    </div>
                    <div className="quickCart-container__drawer-footer__btn-container">
                        <button
                            // appearance="primary"
                            className="quickCart-container__drawer-footer__checkout-button"
                            onClick={() => {
                                // setSelectedSubContent("BagList");
                                // setOpenDrawer(false);
                                // setOpenDeliveryTypeModal(true)
                                handlePlaceOrderClick()
                            }}
                        >
                            Place Order
                        </button>
                    </div>
                </div>
            </div>
            <div className="quickCart-container__heading">
                <div className="quickCart-container__heading__inputFiled">
                    <div className="quickCart-container__heading__inputFiled__label">Source Location</div>
                    <div className="quickCart-container__heading__inputFiled__value">
                        <input
                            readOnly
                            value={hubLocation.location_name}

                            //placeholder="Enter Confirm Password"
                            className="quickCart-container__heading__inputFiled__value__input"

                        //onChange={(e) => { setUpdatePasswordData({ ...updatePasswordData, ["confirm_password"]: e.target.value }) }}
                        /></div>

                </div>
                <div className="quickCart-container__heading__inputFiled">
                    <div className="quickCart-container__heading__inputFiled__label">Destination Location</div>
                    <div className="quickCart-container__heading__inputFiled__value">
                        {orderType !== "Self" ? <select
                            value={destLocation}
                            onChange={(e) => { setDestLocation(e.target.value) }}
                            //defaultValue={destLocation}
                            //placeholder="Enter Confirm Password"
                            className="quickCart-container__heading__inputFiled__value__input"
                        >
                            <option value={"Select"}>
                                Select
                            </option>
                            {allLocations.map((option) => {
                                //console.log("option", option._id, option.location_name)
                                if (option.tag_name !== "Office" && option.tag_name !== "Meghalaya")
                                    return <option key={option._id} value={[option._id, option.location_name]}>
                                        {option.location_name}
                                    </option>
                                else {
                                    return
                                }
                            })}</select> :
                            <div className="quickCart-container__heading__inputFiled__value__input">
                                {avaiblableDestLocations[myLocation]}
                            </div>}
                    </div>
                </div>
            </div>

            {/* </Drawer.Header> */}
            <div className='quickCart-container__delivery-type'>
                <Form.Group controlId="delivery-type">
                    <div className='quickCart-container__delivery-type__container'>
                        <Form.ControlLabel style={{ fontSize: '16px' }} className='quickCart-container__delivery-type__container__title'>
                            Delivery Type
                        </Form.ControlLabel>
                        <RadioGroup
                            inline
                            value={deliveryType}
                            onChange={(value) => {
                                // setDeliveryType(value);
                                handleDeliveryRadioChange(value)
                                // setSelectDateVisibility(value === 'Scheduled');
                            }}
                            style={{ display: 'flex', gap: '1rem', fontSize: '14px' }}
                        >
                            <div className='quickCart-container__delivery-type__container__radiobtn'>
                                <Radio value="Urgent">Urgent</Radio>
                                <Radio value="Scheduled">Scheduled</Radio>
                            </div>
                        </RadioGroup>
                    </div>
                    {deliveryType === "Urgent" &&
                        (<div className='quickCart-container__delivery-type__urgent-reason' style={{ border: urgentReason === "" || urgentReason === "Select" ? "1px solid #FD6125" : "" }}>
                            <div className='quickCart-container__delivery-type__urgent-reason__label'>Reason</div>
                            {/* <div className='quickCart-container__delivery-type__urgent-reason__colon'>:</div> */}
                            <select
                                className='quickCart-container__delivery-type__urgent-reason__options'
                                defaultValue={urgentReason}
                                value={urgentReason}
                                onChange={(e) => {
                                    console.log("e", e.currentTarget.value)
                                    setUrgentReason(e.currentTarget.value)
                                }}>
                                <option>
                                    Select
                                </option>
                                {urgentReasonOption.map((reasons, i) => {
                                    return <option key={i + 1} value={reasons.label}>{reasons.label}</option>
                                })}
                            </select>
                        </div>)
                    }

                    {deliveryType === "Scheduled" &&
                        (<div className='quickCart-container__delivery-type__calander'>
                            <DatePicker className='quickCart-container__delivery-type__calander__datepicker'
                                format="dd/MM/yyyy"
                                oneTap={true}
                                value={deliveryDate}
                                onChange={(date) => setDeliveryDate(date)}
                                // shouldDisableDate={(date) => {
                                //     const availableDates = availableOrderSlots.map(slot => dayjs(slot).startOf('day').toString());
                                //     const selectedDate = dayjs(date).startOf('day').toString();
                                //     return !availableDates.includes(selectedDate);
                                // }}
                                shouldDisableDate={(date) => {
                                    const availableDates = availableOrderSlots.map(slot => dayjs(slot).startOf('day'));
                                    const selectedDate = dayjs(date).startOf('day');
                                    const today = dayjs().startOf('day');

                                    return selectedDate.isSame(today) || !availableDates.some(availableDate => availableDate.isSame(selectedDate));
                                }}
                                placement='bottomRight'
                            />
                        </div>)
                    }
                </Form.Group>
            </div>
            <div className="quickCart-container__tableHeader">
                <div className="quickCart-container__tableHeader__drawer-header-title">Name</div>
                <div className="quickCart-container__tableHeader__drawer-header-quantity">Quantity</div>
                <div className="quickCart-container__tableHeader__drawer-header-delete">Delete</div>
            </div>
            <div className="quickCart-container__drawer-body">
                <div className="quickCart-container__drawer-body__item-list">
                    {bagList.map((cartitem, i) => (
                        <CartRowItem item={cartitem} i={i} bagList={bagList} setBagList={setBagList} SetLoadingVisibility={SetLoadingVisibility} userCookie={userCookie} handleDeleteCartItem={handleDeleteCartItem} />
                    ))}
                </div>
            </div>

            <div className="quickCart-container__drawer-footer">


                <div className="quickCart-container__drawer-footer__total-weight">
                    Total Weight: {calculateTotalWeight()} g
                </div>
                {extraWeight < 0 ?
                    <div className="quickCart-container__drawer-footer__btn-container">
                        {/* <button
                        // appearance="primary"
                        className="quickCart-container__drawer-footer__checkout-button"
                        onClick={() => {
                            // setSelectedSubContent("BagList");
                            // setOpenDrawer(false);
                            // setOpenDeliveryTypeModal(true)
                            handlePlaceOrderClick()
                        }}
                    >
                        Place Order
                    </button> */}
                        <div style={{ color: "red" }}>Please remove some item</div>
                    </div> :
                    <div className="quickCart-container__drawer-footer__btn-container">
                        <div style={{ color: "#FD6125" }}>You can add {extraWeight}g more weight to this order.</div>
                    </div>
                }

            </div>
        </div>

    )
}

export default QuickCheckoutCart