import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import AddCategoryForm from "../AddCategoryForm/AddCategoryForm";
import CategoryPopUp from "../CategoryPopUp/CategoryPopUp";
import { backEndIP } from "../configuration";
import "./CategoryAdd.scss";

const CategoryAdd = ({ 
    SetCategories,
    userCookie,
    SetLoadingVisibility,
    handleNotifyAlert, categories, setCategories}) => {

    // const [categories, setCategories] = useState([])
    const [addCategoryPopup, setAddCategoryPopup] = useState(false)
    const [currentCategory, setCurrentCategory] = useState(false);
    const [showDeleteCategory, setShowDeleteCategory] = useState(false);
    useEffect(() => {
        axios({
            url: backEndIP + "/category/get_category",
            method: "GET",
            headers: {
                authorization: userCookie.token,
            },
        }).then((data) => {
           // console.log(data.data.data[0]);
            setCategories(data.data.data);
            // setCategory(data.data.data[0].name)
        });
    }, []);
    const handleDeleteBtn = (_id)=>{
        //console.log('item', _id)
        SetLoadingVisibility(true)
        axios({
          url: backEndIP + `/category/delete_category/${_id}`,
          method: "DELETE",
          headers: {
            authorization: userCookie.token,
          },
        }).then((data) => {
          SetLoadingVisibility(false)
          setShowDeleteCategory(false);
          handleNotifyAlert(
            "notify",
            `Category Deleted successfully`,
            "success",
            () => { }
          );
         console.log("resp=",data.data.data)
          //SetLoadingVisibility(false)
          SetCategories(data.data.data)
          setCategories(data.data.data)
          //window.location.reload(false);
        })
          .catch((err) => {
            //console.log(err);
            SetLoadingVisibility(false);
            setShowDeleteCategory(false);
            handleNotifyAlert(
              "notify",
              `${err.response.data.message}`,
              "failed",
              () => { }
            );
          });
    }

    const handledeleteCategory = (item)=>{
        handleNotifyAlert("alert",` Are you sure! You want to Cancel Order No. ${item.name}?`, "failed", () => {handleDeleteBtn(item._id)})
    }

    return (
        <div className="CategoryAddForm">   
            <div className="CategoryAddForm__container">
            <div className="CategoryAddForm__container__addButton">
                    <button className="CategoryAddForm__container__addButton__categoryAddbtn" onClick={() => { setAddCategoryPopup(true) }}>New Category </button>
                </div>
                <div className="CategoryAddForm__container__header">
                    <div className="CategoryAddForm__container__header__title" style={{ width: "calc(200px * var(--heightmultiplier))" }}>
                        S.No
                    </div>
                    <div className="CategoryAddForm__container__header__title" style={{ width: "calc(400px * var(--heightmultiplier))" }}>
                        Name
                    </div>
                    <div className="CategoryAddForm__container__header__title" style={{ width: "calc(400px * var(--heightmultiplier))" }}>
                        No Of Items Present
                    </div>
                    {/* <div className="CategoryAddForm__container__header__title" style={{ width: "calc(300px * var(--heightmultiplier))" }}>
                        Edit
                    </div> */}
                    <div className="CategoryAddForm__container__header__title" style={{ width: "calc(300px * var(--heightmultiplier))" }}>
                        Delete
                    </div>
                </div>
                <div className="CategoryAddForm__container__categoryTable">
                    {categories.map((item, i) => {
                        return (
                            <div className="CategoryAddForm__container__categoryTable__data" key={i}>
                                <div style={{ width: "calc(200px * var(--heightmultiplier))" }} className="CategoryAddForm__container__categoryTable__data__itemStart">{i + 1}</div>
                                <div style={{ width: "calc(400px * var(--heightmultiplier))" }} className="CategoryAddForm__container__categoryTable__data__item">{item.name}</div>
                                <div style={{ width: "calc(400px * var(--heightmultiplier))" }} className="CategoryAddForm__container__categoryTable__data__item">{item.noOfItemsPresent}</div>
                                {/* <div style={{ width: "calc(300px * var(--heightmultiplier))" }} className="CategoryAddForm__container__categoryTable__data__item"><button className="CategoryAddForm__container__categoryTable__data__item__editButton">Edit</button></div> */}
                                <div style={{ width: "calc(300px * var(--heightmultiplier))" }} className="CategoryAddForm__container__categoryTable__data__itemEnd"><div className="CategoryAddForm__container__categoryTable__data__itemEnd__deleteButton" onClick={()=>{handledeleteCategory(item)}}><span className="material-symbols-outlined" style={{fontSize:"calc(28px * var(--heightmultiplier))"}}>
                                    delete
                                </span></div></div>
                            </div>
                        )
                    })}
                </div>
                
            </div>
            {addCategoryPopup ? <CategoryPopUp setOpenModal={setAddCategoryPopup} messageToDisplay={{ message: <AddCategoryForm setAddCategoryPopup={setAddCategoryPopup} userCookie={userCookie} SetLoadingVisibility={SetLoadingVisibility} handleNotifyAlert={handleNotifyAlert} setCategories={setCategories} SetCategories={SetCategories}/>, func: () => { } }} /> : null}
        </div>
    )
}
export default CategoryAdd;